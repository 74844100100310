/* The brightest duo-tone color */
.darken::before {
  background-color: var(--vads-color-primary-darker);
}

/* The darkest duo-tone color */
.lighten::after {
  background-color: var(--vads-color-black);
}

/* Duo-tone effect */
.duotone {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.duotone::before,
.duotone::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.duotone::before {
  mix-blend-mode: color;
}

.duotone::after {
  mix-blend-mode: screen;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* Cheat to restrict to IE10+ CSS styles. */
  .duotone::before,
  .duotone::after {
    width: 0%;
    height: 0%;
  }

  /* Cheat for IE10+ not handling flex box correctly. */
  .vads-l-row {
    min-width: 94%;
    max-width: 94%;
  }
}

@supports (-ms-ime-align: auto) {

  /* Cheat to restrict to IE Edge CSS styles. */
  .duotone::before,
  .duotone::after {
    width: 0%;
    height: 0%;
  }
}

.facility-satisfaction-tile {
  width: 50%;

  @include media($small-screen) {
    width: 144px;
  }
}

.basic-facilities-list {
  .facility {
    &:last-child {
      margin-bottom: 24px;
    }
  }
}

.region-img,
.news-img {
  width: 100%;
  height: 165px;
  object-fit: cover;

  @include media($small-screen) {
    height: 180px;
    width: 342px;
  }

  @include media($medium-screen) {
    height: initial;
    width: initial;
    object-fit: initial;
  }
}

.facility-img {
  width: 100%;
  min-width: 250px;
  min-height: 150px;
  object-fit: cover;

  @include media($small-screen) {
    height: 150px;
    width: 250px;
  }

  @include media($medium-screen) {
    object-fit: initial;
  }
}

.news-img {
  @include media($small-screen) {
    height: 180px;
    width: 100%;
  }

  @include media($medium-screen) {
    height: initial;
    width: initial;
  }
}

.story-detail-img {
  width: 100%;
  height: auto;
  object-fit: cover;

  @include media($medium-screen) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.event-detail-img {
  width: 100%;
  height: 28%;
  object-fit: cover;
}

.region-grid {
  width: 100%;
  padding-right: initial;
  padding-left: initial;

  &:not(:only-child):last-child {
    padding-right: initial;
    padding-left: initial;
  }

  &.stories-list {
    width: 100%;
  }

  @include media($small-screen) {
    width: 50%;
    padding-right: 12px;
    padding-left: 0;

    &:not(:only-child):last-child {
      padding-right: 0;
      padding-left: 12px;
    }

    &.stories-list {
      width: 100%;
    }
  }

  @include media($medium-screen) {
    width: 65%;

    &.event,
    &.facility {
      width: 50%;
    }

    &:not(:only-child):last-child {

      &.event,
      &.facility {
        width: 50%;
      }

      width: 32%;
    }
  }
}

// to push items at the end of an element wrapped in display: flex to the bottom
.va-c-margin-top--auto {
  margin-top: auto;
}

.va-c-position--relative {
  position: relative;
}

.va-c-position--absolute {
  position: absolute;
}

.va-c-position-top-right-corner {
  right: 0;
  top: 0;
}

.expand-image-button {
  background-color: var(--vads-color-primary);
  border-radius: 5px;
  color: var(--vads-color-white);
  height: 32px;
  width: 32px;

  &:visited {
    background-color: var(--vads-color-primary);
    color: var(--vads-color-white);
  }

  &:hover {
    background-color: var(--vads-color-primary-dark);
    color: var(--vads-color-white);
  }
}

// featured content
.featured-content-list-item {
  width: 100%;
  margin-right: 1em;
  word-wrap: break-word;

  &:last-child {
    margin-right: 0;
  }

  @include media($medium-screen) {
    width: 33%;
  }
}

.featured-content-hr {
  width: 40px;
}


.bio-paragraph-image {
  height: 110px;
  width: 110px;
}

.bio-image {
  max-height: 178px;
}

.person-profile-detail-page-image {
  max-height: 227px;
}

.force-small-header {
  font-size: 1em;
  font-family: "Source Sans Pro";
  margin-top: 1em;
}

.va-facility-page {
  .va-address-block {
    margin-left: 0.625rem;
    border-left: none;
  }
}

.facilities_health_service {
  button.usa-button {
    margin-bottom: 0;
  }
}

.va-l-font-size--12px {
  font-size: 12px !important;
}

.circular-profile-missing-icon {
  position: absolute;
  top: 31px;
  left: 31px;
}

// OPERATING STATUS

.operating-status-flag {
  width: 286px;
  height: 40px;
  transition: background 0.25s;
}

.operating-status-flag .os-icon {
  padding-right: 7px;
}

a.operating-status-link {
  color: initial;
  display: inline-block;
  font-weight: 700;
}

.operating-status-flag .os-chevron-right {
  font-size: 12px;
}

.operating-status-flag-notice:hover {
  background: rgba(155, 218, 241, 0.7);
}

.operating-status-flag-warning:hover {
  background: rgba(250, 217, 128, 0.7);
}

.operating-status-flag-error:hover {
  background: rgba(229, 147, 147, 0.7)
}

.operating-status-flag-notice:active {
  background: rgba(155, 218, 241, 1);
}

.operating-status-flag-warning:active {
  background: rgba(250, 217, 128, 1);
}

.operating-status-flag-error:active {
  background: rgba(229, 147, 147, 1)
}